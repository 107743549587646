import React, {PureComponent, ReactNode} from "react";
import booknowdesktop from "../../../public/assets/images/werkstatt-termin-buchen.jpg";
import booknowmobile from "../../../public/assets/images/werkstatt-termin-buchen-mobile.jpeg";

export type BookNowProps = {
    headline: string,
    text: string,
    headlineAddition: string,
};

export class BookNow extends PureComponent<BookNowProps> {

    render(): ReactNode {
        const {
            headline,
            text,
            headlineAddition
        } = this.props

        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        };

        return (
            <div className={`bookNow mx-4 md:mx-12 lg:pl-12 mb-12 grid grid-cols-2 gap-x-24 bg-gray-700 rounded-md`}>
                <div className={'bookNow__textBlock col-span-2 lg:col-span-1 pt-10 lg:py-20 px-8 md:px-12 lg:px-0'}>
                    <h2 className={`text-3xl leading-9 font-semibold md:text-4xl md:leading-10 font-serif text-white pb-4`}>{headline}<br/>{headlineAddition}</h2>
                    <p className={'text-lg leading-7 font-normal font-serif text-gray-200 pb-8'}>{text}</p>
                    <div className={"button text-base leading-6 font-medium relative"}
                    onClick={scrollToTop}>
                        <button className={`bg-white rounded-md p-2 text-gray-900 w-full lg:w-auto mb-8 lg:mb-0 px-5 py-3`}>Werkstatt Termin finden</button>
                    </div>
                </div>
                    <picture className={'aboutUs__ImageBlock col-span-2 lg:col-span-1 w-full h-full min-h-[155px] aspect-auto'}>
                        <source media="(max-width: 975px)" srcSet={`${booknowmobile.src}`}/>
                        <source media="(min-width: 976px)" srcSet={`${booknowdesktop.src}`}/>
                        <img loading={'lazy'} className={'object-cover min-h-full w-full rounded-md'} src={booknowdesktop.src} alt="gesammelte Werkstattbilder" width={600} height={432}/>
                    </picture>
            </div>
        );
    }
}